import React from "react"
import Layout from "components/layout/layout"

const Policy = () => {
  return (
    <Layout pageTitle="Política de Privacidade">
      <div className="u-wrapper u-wrapper--800">
        <p>
          Prezado candidato, 
          <br />
          <br />
          A StoneCo. preza pela sua privacidade e possui um compromisso com a
          transparência e proteção dos dados pessoais de todos os candidatos.
          <br />
          <br />
          Durante este processo seletivo, vamos coletar alguns de seus dados
          pessoais para concluirmos sua candidatura e a análise de seu perfil
          profissional.
        </p>
        <p className="c-policy__title u-text-center">
          AVISO DE TRATAMENTO DE DADOS PESSOAIS{" "}
        </p>
        <p className="c-policy__subtitle">1. TERMOS E DEFINIÇÕES:</p>
        <ul className="c-policy__list">
          <li>
            <span>DADOS PESSOAIS</span>: Qualquer informação que possa levar à
            identificação direta ou indireta de uma pessoa física. O conceito
            inclui nome, e-mail, RG, CPF, qualquer tipo de documento de
            identidade, telefone, dados cadastrais, endereço, imagem das pessoas
            captadas por câmeras, dados de candidatos à vagas na empresa
            (inclusive informações constantes no CV), entre outros.
          </li>
          <li>
            <span>DADOS PESSOAIS SENSÍVEIS</span>: Dado pessoal que diga
            respeito à origem racial ou étnica; convicção religiosa; opinião
            política; filiação a sindicato ou a organização de caráter
            religioso; filosófico ou político; referente à saúde ou à vida
            sexual; genético ou biométrico; quando vinculado a uma pessoa
            natural.
          </li>
          <li>
            <span>TRATAMENTO</span>: toda operação realizada com Dados Pessoais
            dentro de seu ciclo de vida, como coleta, produção, recepção,
            classificação, utilização, acesso, reprodução, transmissão,
            distribuição, processamento, arquivamento, armazenamento,
            eliminação, avaliação ou controle da informação, modificação,
            comunicação, transferência, difusão ou extração.
          </li>
        </ul>

        <p className="c-policy__subtitle">2. COMO COLETAMOS DADOS PESSOAIS:</p>
        <ul className="c-policy__list">
          <li>
            <span>DADOS ENVIADOS PELO CANDIDATO</span>: durante o processo
            seletivo, os candidatos devem preencher alguns formulários com
            informações pessoais e profissionais. Também podem enviar seus
            currículos via e-mail ou por sites e plataformas parceiras, como
            forma de se candidatarem a uma das vagas disponíveis em qualquer das
            empresas StoneCo.
          </li>
          <li>
            <span>DADOS QUE COLETAMOS EM NOSSAS ROTINAS E DISPOSITIVOS</span>:
            dados pessoais poderão ser coletados em nossas rotinas de seleção e
            por meio de alguns de nossos dispositivos, como na identificação dos
            candidatos na sua entrada em nossas instalações.
          </li>
          <li>
            <span>SITES E PLATAFORMAS PARCEIRAS</span>: os candidatos podem
            enviar ou ter seus dados pessoais coletados por meio de candidaturas
            em sites e plataformas parceiras.
          </li>
          <li>
            <span>BANCOS DE DADOS PÚBLICOS E PRIVADOS</span>: os candidatos
            podem ter seus dados pessoais coletados por meio de consultas em
            órgãos públicos e privados.
          </li>
        </ul>

        <p className="c-policy__subtitle">3. DADOS PESSOAIS QUE COLETAMOS: </p>
        <ul className="c-policy__list">
          <li>
            <span>DADOS CADASTRAIS</span>: utilizamos o nome; endereço; número,
            cópias físicas ou disponibilização digital de documentos pessoais
            como CPF e identidade; data de nascimento; filiação; perfis em redes
            sociais; informações de contato como número de telefone, e-mail e
            endereço residencial completo.
          </li>
          <li>
            <span>DADOS PROFISSIONAIS</span>: durante o processo seletivo,
            coletamos dados de experiências profissionais anteriores,
            credenciais acadêmicas; informações sobre atividades
            extracurriculares realizadas, domínio de línguas estrangeiras e
            domínio técnico e prático das funções requeridas nas vagas.
          </li>
          <li>
            <span>DADOS COMPORTAMENTAIS</span>: podemos coletar e analisar
            informações comportamentais do candidato durante o processo
            seletivo.
          </li>
          <li>
            <span>DADOS BIOMÉTRICOS FACIAIS</span>
          </li>
          <li>
            <span>DADOS DE SAÚDE</span>: beneficiário reabilitado, pessoa com
            deficiência e restrições alimentares.
          </li>
          <li>
            <span>DADOS DEMOGRÁFICOS</span>: podemos coletar a analisar dados de
            autodeclaração de gênero e origem racial ou étnica
          </li>
        </ul>

        <p className="c-policy__subtitle">
          4. COMO UTILIZAMOS DADOS PESSOAIS:{" "}
        </p>
        <p className="c-policy__text">
          Utilizamos os dados pessoais coletados para finalidades diversas, tais
          como:
        </p>
        <ul className="c-policy__list">
          <li>
            Enviar avisos sobre as candidaturas e o seguimento dos processos
            seletivos por meio de e-mails, mensagens, SMS, telefonemas, dentre
            outras formas de comunicação;
          </li>
          <li>Identificar os candidatos durante o processo seletivo;</li>
          <li>Viabilizar o acesso do candidato às nossas instalações;</li>
          <li>Agendar entrevistas;</li>
          <li>
            Analisar o perfil profissional do candidato e sua possível aderência
            às vagas disponíveis em nossos processos seletivos;
          </li>
          <li>
            Realizar levantamento estatístico para avaliação de diversidade no
            processo seletivo;
          </li>
          <li>
            Estudos sobre o perfil dos candidatos nos processos seletivos.
          </li>
        </ul>

        <p className="c-policy__subtitle">
          5. QUEM TEM ACESSO AOS DADOS PESSOAIS:{" "}
        </p>
        <p className="c-policy__text">
          Os dados pessoais coletados serão analisados pelos profissionais
          responsáveis pela condução do processo seletivo.
        </p>
        <p className="c-policy__text">
          A StoneCo. poderá compartilhar seus dados pessoais com empresas do seu
          grupo econômico e/ou franquias, com a finalidade de oferecer vagas
          disponíveis em nossos processos seletivos, resguardados seus legítimos
          interesses e os seus Direitos como Titular de Dados.
        </p>
        <p className="c-policy__text">
          Vagas destinadas a jovens aprendizes e estagiários terão seus dados
          compartilhados com as organizações parceiras da StoneCo.e agentes
          integradores nessa contratação.
        </p>
        <p className="c-policy__text c-policy__mb--35">
          Além disso, as empresas Greenhouse e Mindsight, podem ter acesso aos
          dados pessoais coletados durante nossos processos seletivos, como
          forma de viabilizar a análise de perfil, inclusive comportamental, dos
          candidatos. Todas essas empresas, assim como toda a StoneCo., possuem
          compromisso com a proteção de dados pessoais, e cumprem as leis de
          proteção de dados em vigor.
        </p>

        <p className="c-policy__subtitle">
          6. COMO PROTEGEMOS DADOS PESSOAIS:{" "}
        </p>
        <p className="c-policy__text">
          Implementamos as medidas de segurança ao nosso alcance para proteger
          os seus dados pessoais de perdas, interferências, utilização
          imprópria, acesso não autorizado, divulgação, alteração ou destruição.
        </p>
        <p className="c-policy__text">
          As empresas StoneCo. também mantêm procedimentos para garantir
          que tais informações sejam confiáveis para o uso a que se destinam,
          bem como sejam corretas, completas e atuais.
        </p>
        <p className="c-policy__text">
          Veja alguns exemplos de medidas que tomamos para proteger seus dados:
        </p>
        <ul className="c-policy__list">
          <li>
            Todas as áreas da empresa passam obrigatoriamente por treinamentos
            sobre a LGPD e demais regulações para adquirirem conhecimento
            específico sobre como tratar de forma adequada e legal os dados
            pessoais;
          </li>
          <li>
            A StoneCo. possui um Time de Privacidade que encontra-se à
            disposição para eventuais dúvidas nas tratativas dos dados e
            sempre auxiliará os times guiando-os em conformidade com a lei;
          </li>
          <li>
            Todos os colaboradores devem ler e pôr em prática as políticas e
            diretrizes da empresa;
          </li>
          <li>
            É realizado due diligence de todos os fornecedores e parceiros
            StoneCo. a fim de garantir que todos esses estejam com grau
            aceitável de maturidade em relação à Privacidade e Proteção de
            Dados.
          </li>
        </ul>
        {/* Note: The following code is a workaround for an unknown behavior
        which led to the classes of the previous tags being ṕassed to the next
        TODO: FIX THIS BEHAVIOR*/}
        <p className="c-policy__subtitle">7. RETENÇÃO E DIREITOS: </p>
        <p className="c-policy__text">
          Os dados pessoais dos candidatos serão tratados pelo tempo necessário
          à execução das atividades, observando, sempre que aplicáveis, as
          disposições legais. Assim, mesmo quando a exclusão for solicitada pelo
          candidato, a StoneCo. poderá manter determinados dados caso ainda haja
          fundamento legal para tanto, de acordo com as seguintes diretrizes:
        </p>
        <ul className="c-policy__list">
          <li>
            Somente poderão ser retidos dados estritamente necessários para o
            cumprimento das finalidades do tratamento. O usuário poderá
            solicitar a exclusão de dados pessoais desnecessários, excessivos
            ou tratados em desconformidade;
          </li>
          <li>
            Os períodos de retenção deverão ser cumpridos conforme a natureza
            dos dados pessoais e a finalidade do tratamento e observar, sempre
            que aplicável, as disposições legais e regulatórias que indiquem
            um prazo específico de guarda de documentos;
          </li>
          <li>
            A retenção do dado pessoal deverá ser fundamentada e justificável,
            dependendo da finalidade do Tratamento.
          </li>
        </ul>
        <p className="c-policy__text">
          O titular dos dados pessoais terá os seguintes direitos, de acordo com
          o artigo 18 da Lei nº 13.709/18 (LGPD):
        </p>
        <ul className="c-policy__list">
          <li>Confirmação da existência de tratamento;</li>
          <li>Acesso aos dados;</li>
          <li>Correção de dados incompletos, inexatos ou desatualizados;</li>
          <li>
            Anonimização, bloqueio ou eliminação de dados desnecessários,
            excessivos ou tratados em desconformidade;
          </li>
          <li>
            Portabilidade dos dados a outro fornecedor de serviço ou produto,
            mediante requisição expressa, de acordo com a regulamentação da
            autoridade nacional, observados os segredos comercial e
            industrial;{" "}
          </li>
          <li>
            Eliminação dos dados pessoais tratados com o consentimento do
            titular, exceto nas hipóteses de: cumprimento de obrigação legal
            ou regulatória pelo controlador; estudo por órgão de pesquisa,
            garantida, sempre que possível, a anonimização dos dados pessoais;
            transferência a terceiro, desde que respeitados os requisitos de
            tratamento de dados dispostos na Lei; ou uso exclusivo do
            controlador, vedado seu acesso por terceiro, e desde que
            anonimizados os dados.
          </li>
          <li>
            Informação das entidades públicas e privadas com as quais o
            controlador realizou uso compartilhado de dados;
          </li>
          <li>
            Informação sobre a possibilidade de não fornecer consentimento e
            sobre as consequências da negativa;
          </li>
          <li>
            A revogação do consentimento pode ocorrer a qualquer momento
            mediante manifestação expressa do titular, por procedimento
            gratuito e facilitado, ratificados os tratamentos realizados sob
            amparo do consentimento anteriormente manifestado enquanto não
            houver requerimento de eliminação.
          </li>
        </ul>
        <p className="c-policy__text">
          Caso queira exercer qualquer direito sobre suas informações, você
          poderá realizar o pedido através do nosso{" "}
          <a href="https://privacidade.stone.com.br/">
            Portal de Privacidade.{" "}
          </a>
        </p>
      </div>
    </Layout>
  )
}

export default Policy
